import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from '../pages/admin/modal-overlays/loader';

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      all_products: [],
      selected_products: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapshot) => {
        let tempArr = [];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.getUserProducts(tempArr);
      })
      .catch((err) => {
        _self.setState({ isLoading: false });
      });
  };

  getUserProducts = (allProds) => {
    let _self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection('user_subscriptions')
          .where('userId', '==', user.uid)
          .get()
          .then((snapshot) => {
            let selected = [];
            snapshot.forEach((doc) => {
              selected = doc.data().productIds;
            });
            _self.setState({ isLoading: false, all_products: allProds, selected_products: selected });
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
          });
      } else {
        _self.setState({ isLoading: false });
        navigate('/login');
      }
    });
  };

  buyNow = () => {};

  goToReportList = (productId) => {
    let time = this.state.selected_products.find((item) => item.product_id == productId).purchase_date.seconds;

    localStorage.setItem('product_id', productId);
    localStorage.setItem('time', time);
    navigate('/report-listing');
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="price-card-group row">
          {this.state.all_products.length ? (
            this.state.all_products.map((product, i) => (
              <div key={i} className="col-4">
                <div className="card">
                  <div className="card-body justify-content-center">
                    <h6 className="mb-3">
                      <u>{product.name}</u>
                    </h6>
                    <ul className="features-list">
                      {product.selectedIns.map((item, j) => (
                        <li key={j}>{item.label}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="btn-wrapper">
                    {this.state.selected_products.findIndex((item) => item.product_id == product.id) == -1 ? (
                      <button onClick={this.buyNow} className="btn btn-primary">
                        Buy Now
                      </button>
                    ) : (
                      <button className="btn btn-success" onClick={() => this.goToReportList(product.id)}>
                        Read
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h6>No Products</h6>
          )}
        </div>
        <Loader isOpen={this.state.isLoading} />
      </div>
    );
  }
}

export default Products;
